@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

body {
  background-color: #34495e;
}

header {
  margin-top: 100px;
}
header h1,
header h3 {
  text-align: center;
  font-family: "Architects Daughter", cursive;
  color: #95a5a6;
}

.container {
  width: 50%;
  position: relative;
  margin: 0 auto;
  z-index: 1;
}

.wrapBox {
  animation-delay: 1s;
  animation-duration: 3s;
}

.box {
  background-color: #e74c3c;
  width: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  overflow: visible;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24);
}

.box label {
  color: #fff;
  font-size: 1.3em;
  font-family: "Dosis", sans-serif;
}

.box input[type="email"] {
  background: transparent;
  border: none;
  border-bottom: solid 1px #fff;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.1em;
  margin-top: 10px;
  color: #fff;
  font-family: "Dosis", sans-serif;
}

.box input[type="email"]:focus,
.box input[type="email"]:active,
.box button[type="submit"]:focus,
.box button[type="submit"]:active {
  outline: none;
  /*border-bottom: solid 3px #FFF; */
}

.box button[type="submit"] {
  margin: 30px auto;
  text-align: center;
  border: none;
  background: transparent;
  border: solid 3px #fff;
  padding: 5px 15%;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5em;
  font-family: "Architects Daughter", cursive;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.box button[type="submit"] .fa {
  margin-left: 10px;
}

.box button[type="submit"]:hover {
  background-color: #fff;
  color: #e74c3c;
}

.box .social {
  margin: 0 auto;
  text-align: center;
}

.box .social ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.box .social ul li {
  display: inline-block;
  font-size: 2em;
  color: #fff;
  margin-left: 25px;
  cursor: pointer;
}


.box .social ul li a{
  color: #fff;
}


.thanks {
  position: absolute;
  z-index: 999;
  height: 80px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.thanks p {
  font-size: 4em;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  font-family: "Architects Daughter", cursive;
}

/* Responsive */
/* Desktops and laptops ----------- */
@media only screen and (max-width: 1024px) {
  .container {
    width: 60%;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}